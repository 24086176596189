import React from 'react';
import Layout from 'layout';
import { ExtendGridBox } from 'pages/integrations/analytics';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const UXDesigners = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Google Analytics');
  return (
    <Layout
      metaTitle="UX Designers | Integrations"
      canonical="/integrations/for-ux-designers/"
      metaDescription="Find usability issues and see how the users interact with your products. Add LiveSession to your UX design stack."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/for-ux-designers/">
                <strong>UX Designers</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="UX Designers" />
          <Content activeCategory="UX Designers" hideTitle>
            <Banner
              activeCategory="UX Designers"
              card={mostPopularCard}
              text="Find usability issues and see how the users interact with your products. Add LiveSession to your UX design stack."
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default UXDesigners;
